<template>
    <a-card>
        <a-page-header
            title="新建创意标题模板"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="标题适用范围" prop='scope'>
                <a-radio-group v-model="form.scope" @change="onChangeScope">
                    <a-radio :value="1">车系</a-radio>
                    <a-radio :value="2">品牌</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                <a-select
                    v-model="form.principalId"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="form.scope != 2">
                <a-select
                    v-model="form.carSeriesId"
                    placeholder="请选择车系"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    :disabled="!form.principalId"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="集客场景" prop='customerScene' style="margin-top: 24px;">
                <a-radio-group v-model="form.customerScene">
                    <a-radio
                        v-for="item in customerSceneList"
                        :key="item.code"
                        :value="item.code"
                    >
                        {{ item.desc }}
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="标题有效日期" ref="validityType" prop="validityType">
                <a-radio-group v-model="form.validityType" @change="onChangeValid">
                    <a-radio :value="1">长期</a-radio>
                    <a-radio :value="2">
                        截止
                        <a-date-picker
                            v-model="dateValue"
                            placeholder="请选择定时发布时间"
                            :disabled-date="disabledDate"
                            :disabled="form.validityType != 2"
                            @change="onChangeDate"
                        />
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="创意标题模板" ref="creativeTitle" prop='creativeTitle'>
                <div>
                    <wordLimitInput 
                        :value="form.creativeTitle"
                        :maxLength="30"
                        @onChangeInput="onChangeInput"
                    ></wordLimitInput>
                    <a-space>
                        <span>插入动态词包：</span>
                        <a-button
                            type="link"
                            v-for="item in tagList"
                            :key="item.id"
                            @click="onClickTag(item)"
                        >
                            +{{ item.name }}
                        </a-button>
                    </a-space>
                </div>
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'
import moment from 'moment'

export default {
    components: {
        wordLimitInput
    },
    data() {
        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        let checkVideoValidDate = (rule, value, callback) => {
            if (!value || value == 2 && !this.dateValue) {
                callback(new Error('请选择视频有效日期'))
            } else {
                callback();
            }
        }
        
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                scope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                customerScene: 1,
                creativeTitle: '',
                validityType: 1,
                expireDate: '',
            },
            rules: {
                scope: [
                    { required: true, message: '请选择标题适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ],
                creativeTitle: [
                    { required: true, message: '请输入创意标题模板', trigger: 'change' }
                ],
                validityType: [
                    { required: true, validator: checkVideoValidDate, trigger: 'change' }
                ],
            },
            isSubmit: false,
            principalList: [],
            seriesList: [],
            tagList: [
                { id: 1, name: '地点' },
                { id: 2, name: '年龄' },
                { id: 3, name: '月份' },
                { id: 4, name: '节日' },
            ],
            customerSceneList: [],
            dateValue: null,
        }
    },
    created() {
        this.getPrincipalList()
        this.getCustomerScene()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        onChangeInput(val) {
            this.form.creativeTitle = val
        },
        onClickTag(item) {
            this.form.creativeTitle = `${this.form.creativeTitle}{${item.name}}`
            this.$refs.creativeTitle.onFieldChange()
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/creativeTitleManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    if(this.form.scope == 2) {
                        this.form.carSeriesId = undefined
                    }
                    this.$api.core.materialManage.submitCreativeTitleDetail( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/creativeTitleManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        onChangeValid() {
            this.form.expireDate = ''
            this.dateValue = null
        },
        onChangeDate(date, dateString) {
            this.form.expireDate = `${dateString} 23:59:59`
            this.$refs.validityType.onFieldChange()
        },
        disabledDate(current) {
            return  current && current < moment().endOf('day');
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>